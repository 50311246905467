import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

// Получение списка занятости
export const fetchWork = createAsyncThunk('work/fetchWork', async () => {
  const { data } = await axios.get('/api/work');
  return data;
});

// Добавление занятости в БД
export const fetchWorkAdd = createAsyncThunk('work/fetchWorkAdd', async (params) => {
  const { data } = await axios.post('/api/work', params);
  return data;
});

// Изменение существующей занятости по id
export const fetchWorkChange = createAsyncThunk('work/fetchWorkChange', async (params) => {
  const { data } = await axios.put(`/api/work/${params.id}`, params);
  return data;
});

// Удаление занятости по id
export const fetchWorkDelete = createAsyncThunk('work/fetchWorkDelete', async (id) => {
  const { data } = await axios.delete(`/api/work/${id}`);
  return data;
});

const initialState = {
  work: {
    items: [],
    status: 'loading'
  }
};

const workSlice = createSlice({
  name: 'work',
  initialState,
  extraReducers: {

    // Получение списка занятости
    [fetchWork.pending]: (state) => {
      state.work.status = [];
      state.work.status = 'loading';
    },
    [fetchWork.fulfilled]: (state, actions) => {
      state.work.items = actions.payload;
      state.work.status = 'loaded';
    },
    [fetchWork.rejected]: (state) => {
      state.work.status = [];
      state.work.status = 'error';
    },

    // Добавление занятости в БД
    [fetchWorkAdd.pending]: (state) => {
      state.work.status = [];
      state.work.status = 'loading';
    },
    [fetchWorkAdd.fulfilled]: (state, actions) => {
      state.work.items = actions.payload;
      state.work.status = 'loaded';
    },

    // Изменение существующей занятости по id
    [fetchWorkChange.pending]: (state) => {
      state.work.status = [];
      state.work.status = 'loading';
    },
    [fetchWorkChange.fulfilled]: (state, actions) => {
      state.work.items = actions.payload;
      state.work.status = 'loaded';
    },

    // Удаление занятости по id
    [fetchWorkDelete.pending]: (state) => {
      state.work.status = [];
      state.work.status = 'loading';
    },
    [fetchWorkDelete.fulfilled]: (state, actions) => {
      state.work.items = actions.payload;
      state.work.status = 'loaded';
    }
  }
});

export default workSlice.reducer;

export const workReducer = workSlice.reducer;

