import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

export const fetchAuth = createAsyncThunk('auth/fetchAuth', async (params) => {
  const { data } = await axios.post('/api/auth/login', params);
  return data;
});

export const fetchAuthYandex = createAsyncThunk('auth/fetchAuthYandex', async (token) => {
  const { data } = await axios.post('/api/auth/yandexlogin', { token });
  return data;
});

export const fetchRegister = createAsyncThunk('auth/fetchRegister', async (params) => {
  const { data } = await axios.post('/api/auth/register', params);
  return data;
});

export const sendCode = createAsyncThunk('auth/sendCode', async (phone) => {
  const { data } = await axios.get(`/api/phone/${phone}`);
  return data;
});

export const checkCode = createAsyncThunk('auth/checkCode', async (code, thunkAPI) => {
  const { auth } = thunkAPI.getState();
  const { data } = await axios.post(`/api/phone/checkstatus`, {
    code,
    id: auth.idCode
  });
  return data;
});

const initialState = {
  data: null,
  status: 'loading',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.data = null;
    },
  },
  extraReducers: {
    [fetchAuth.pending]: (state) => {
      state.status = 'loading';
      state.data = null;
    },
    [fetchAuth.fulfilled]: (state, action) => {
      state.status = 'loaded';
      state.data = action.payload;
    },
    [fetchAuth.rejected]: (state) => {
      state.status = 'error';
      state.data = null;
    },
    [fetchRegister.pending]: (state) => {
      state.status = 'loading';
      state.data = null;
    },
    [fetchRegister.fulfilled]: (state, action) => {
      state.status = 'loaded';
      state.data = action.payload;
    },
    [fetchRegister.rejected]: (state) => {
      state.status = 'error';
      state.data = null;
    },
    [sendCode.pending]: (state) => {
      state.status = 'loading';
    },
    [sendCode.fulfilled]: (state, action) => {
      state.status = 'loaded';
      state.idCode = action.payload.idCode;
      state.isSendingCode = true
    },
    [sendCode.rejected]: (state) => {
      state.status = 'error';
    },
    [fetchAuthYandex.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchAuthYandex.fulfilled]: (state, action) => {
      state.status = 'loaded';
      state.data = action.payload;
    },
    [fetchAuthYandex.rejected]: (state) => {
      state.status = 'error';
    },
  },
});

export const selectIsAuth = (state) => Boolean(state.auth.data);

export const authReducer = authSlice.reducer;

export const { logout } = authSlice.actions;