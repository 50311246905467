import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { fetchAuth, selectIsAuth, sendCode } from "../../redux/slices/auth";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import "./Authorization.scss";

const Authorization = () => {
  const isAuth = useSelector(selectIsAuth);
  const dispatch = useDispatch();
  const { isPhoneChecked, isSendingCode, idCode } = useSelector(state => state.auth);
  const [authCode, setAuthCode] = useState('');

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {
      "login": "",
      "password": ""
    }
  });

  const handleAuthCode = (e) => {
    setAuthCode(e.target.value)
  }
  const handleSendCodePhoneRegister = () => {
    dispatch(sendCode(getValues('login')))
  }



  const onSubmit = async (values) => {
    const dataSend = {
      ...values,
      code: authCode,
      idCode
    }

    const data = await dispatch(fetchAuth(dataSend));
    window.localStorage.setItem('token', data.payload.accessToken);

    if (!data.payload) {
      return alert('Не удалось авторизоваться!');
    }

    if (data.payload) {
      window.localStorage.setItem('token', data.payload.accessToken);
    }

  };

  if (isAuth) return (
    <Navigate to="/" />
  )

  return (
    <div className="authorization">
      <div className="authorization__header">
        Авторизация
      </div>
      {!isSendingCode ?
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label>
              <input className="nav__sign-modal-input"
                type="number"
                {...register("login", { required: 'Укажите телефон' })}
                placeholder="7 (___) ___ __ __"
              />
            </label>
            <div className="authorization__button" onClick={handleSendCodePhoneRegister}>Продолжить</div>
          </div>
        </form>
        :
        <>
          {isSendingCode && !isPhoneChecked ?
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <p className="modal-btn-mobile">Вам на телефон был отправлен код</p>
                <label>
                  <input className="nav__sign-modal-input"
                    value={authCode}
                    onChange={handleAuthCode}
                    placeholder="Введите код"
                  />
                </label>
                <button type="submit" className="authorization__button">Войти</button>
              </div>
            </form>
            : null}
        </>
      }
    </div>
  );
}

export default Authorization;