import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../../axios";

export const fetchPromo = createAsyncThunk('auth/fetchPromo', async () => {
  const {data} = await axios.get('/api/promo');
  return data;
});

export const deletePromo = createAsyncThunk('auth/deletePromo', async (id,{dispatch}) => {
  await axios.delete(`/api/promo/${id}`);
  dispatch(fetchPromo())
});

export const createPromo = createAsyncThunk('auth/createPromo', async (params,{dispatch}) => {
  await axios.post('/api/promo', params);
  dispatch(fetchPromo())
});


const initialState = {
  data: [],
  status: 'loading',
};

const promoSlice = createSlice({
  name: 'promo',
  initialState,
  extraReducers: {
    // получение списка промокодов
    [fetchPromo.pending]: (state) => {
      state.status = 'loading';
      state.data = [];
    },
    [fetchPromo.fulfilled]: (state, actions) => {
      state.data = actions.payload;
      state.status = 'loaded';
    },
    [fetchPromo.rejected]: (state) => {
      state.data = [];
      state.status = 'error';
    },
    // удаление промокода
    [deletePromo.pending]: (state) => {
      state.status = 'loading';
    },
    [deletePromo.fulfilled]: (state) => {
      state.status = 'loaded';
    },
    [deletePromo.rejected]: (state) => {
      state.status = 'error';
    },
    // добавление промокода
    [createPromo.pending]: (state) => {
      state.status = 'loading';
    },
    [createPromo.fulfilled]: (state) => {
      state.status = 'loaded';
    },
    [createPromo.rejected]: (state) => {
      state.status = 'error';
    },
  },
});

export const promoReducer = promoSlice.reducer;
