import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { fetchRegister, selectIsAuth, sendCode } from '../../redux/slices/auth';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import "../authorization/Authorization.scss";

const Registration = () => {
  const { isPhoneChecked, isSendingCode, idCode } = useSelector(state => state.auth);
  const [authCode, setAuthCode] = useState('');
  const isAuth = useSelector(selectIsAuth);
  const dispatch = useDispatch();

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {
      "login": "",
      "password": ""
    }
  });

  const handleAuthCode = (e) => {
    setAuthCode(e.target.value)
  }
  const handleSendCodePhoneRegister = () => {
    dispatch(sendCode(getValues('login')))
  }

  const onSubmit = async (values) => {
    const dataSend = {
      ...values,
      code: authCode,
      idCode
    }
    const data = await dispatch(fetchRegister(dataSend));

    if (!data.payload) {
      return alert('Не удалось зарегистрироваться!');
    }

    if ('accessToken' in data.payload) {
      localStorage.setItem('token', data.payload.accessToken);
    }
  };

  if (isAuth) {
    return (
      <Navigate to="/" />
    );
  }

  return (
    <div className="authorization">
      <div className="authorization__header">
        Регистрация
      </div>
      {!isSendingCode ?
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label>
              <input className="nav__sign-modal-input"
                type="number"
                {...register("login", { required: 'Укажите телефон' })}
                placeholder="7 (___) ___ __ __"
              />
            </label>
            <div className="authorization__button" onClick={handleSendCodePhoneRegister}>Продолжить</div>
          </div>
        </form>
        :
        <>
          {isSendingCode && !isPhoneChecked ?
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <p className="modal-btn-mobile">Вам на телефон был отправлен код</p>
                <label>
                  <input className="nav__sign-modal-input"
                    value={authCode}
                    onChange={handleAuthCode}
                    placeholder="Введите код"
                  />
                </label>
                <button type="submit" className="authorization__button">Войти</button>
              </div>
            </form>
            : null}
        </>
      }
    </div>
  );
}

export default Registration;