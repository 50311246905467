import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

// Получение списка пользователей
export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const { data } = await axios.get('/api/user');
  return data;
});

// Удаление пользователя
export const fetchUserDelete = createAsyncThunk('user/fetchUserDelete', async (id) => {
  const { data } = await axios.delete(`/api/user/${id}`, id);
  return data;
});

// добавление бонусов
export const updateUserBalance = createAsyncThunk('user/updateUserBalance', async ({id,bonus}) => {
  const { data } = await axios.put(`/api/user/addbonus/${id}`, {bonus});
  return data;
});
// Получение информации о своём аккаунте
export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async () => {
  const { data } = await axios.get('/api/user/info/settings');
  return data;
});

// Изменение информации о своём аккаунте
export const fetchUserInfoChange = createAsyncThunk('user/fetchUserInfoChange', async (params) => {
  const { data } = await axios.put('/api/user/info/settings', params);
  return data;
});

// Получение списка заказов, привязанных к менеджеру
export const fetchUserInfoManager = createAsyncThunk('user/fetchUserInfoManager', async () => {
  const { data } = await axios.get('/api/user/info/orders/manager');
  return data;
});

// Получение списка заказов, привязанных к уборщику
export const fetchUserInfoCleaner = createAsyncThunk('user/fetchUserInfoCleaner', async () => {
  const { data } = await axios.get('/api/user/info/orders/cleaner');
  return data;
});

const initialState = {
  user: {
    items: [],
    status: 'loading'
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: {

    // Получение списка пользователей
    [fetchUser.pending]: (state) => {
      state.user.status = [];
      state.user.status = 'loading';
    },
    [fetchUser.fulfilled]: (state, actions) => {
      state.user.items = actions.payload;
      state.user.status = 'loaded';
    },
    [fetchUser.rejected]: (state) => {
      state.user.status = [];
      state.user.status = 'error';
    },

    // Удаление пользователя
    [fetchUserDelete.pending]: (state) => {
      state.user.status = [];
      state.user.status = 'loading';
    },
    [fetchUserDelete.fulfilled]: (state, actions) => {
      state.user.items = actions.payload;
      state.user.status = 'loaded';
    },
    [fetchUserDelete.rejected]: (state) => {
      state.user.status = [];
      state.user.status = 'error';
    },

    // Получение информации о своём аккаунте
    [fetchUserInfo.pending]: (state) => {
      state.user.status = [];
      state.user.status = 'loading';
    },
    [fetchUserInfo.fulfilled]: (state, actions) => {
      state.user.items = actions.payload;
      state.user.status = 'loaded';
    },
    [fetchUserInfo.rejected]: (state) => {
      state.user.status = [];
      state.user.status = 'error';
    },

    // Изменение информации о своём аккаунте
    [fetchUserInfoChange.pending]: (state) => {
      state.user.status = [];
      state.user.status = 'loading';
    },
    [fetchUserInfoChange.fulfilled]: (state, actions) => {
      state.user.items = actions.payload;
      state.user.status = 'loaded';
    },
    [fetchUserInfoChange.rejected]: (state) => {
      state.user.status = [];
      state.user.status = 'error';
    },

    // Получение списка заказов, привязанных к менеджеру
    [fetchUserInfoManager.pending]: (state) => {
      state.user.status = [];
      state.user.status = 'loading';
    },
    [fetchUserInfoManager.fulfilled]: (state, actions) => {
      state.user.items = actions.payload;
      state.user.status = 'loaded';
    },
    [fetchUserInfoManager.rejected]: (state) => {
      state.user.status = [];
      state.user.status = 'error';
    },

    // Получение списка заказов, привязанных к уборщику
    [fetchUserInfoCleaner.pending]: (state) => {
      state.user.status = [];
      state.user.status = 'loading';
    },
    [fetchUserInfoCleaner.fulfilled]: (state, actions) => {
      state.user.items = actions.payload;
      state.user.status = 'loaded';
    },
    [fetchUserInfoCleaner.rejected]: (state) => {
      state.user.status = [];
      state.user.status = 'error';
    },

    // Добавление бонусов
    [updateUserBalance.pending]: (state) => {
      state.user.status = 'loading';
    },
    [updateUserBalance.fulfilled]: (state, actions) => {
      state.user.status = 'loaded';
    },
    [updateUserBalance.rejected]: (state) => {
      state.user.status = 'error';
    }
  }
});

export const userReducer = userSlice.reducer;