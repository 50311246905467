import { Link, useNavigate } from "react-router-dom";
import { YandexLogin } from "../../components/yandex";
import "../authorization/Authorization.scss";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchAuth } from "../../redux/slices/auth";
import { useState } from "react";
const clientID = '38fad74eb4444c649c22b27c1a04f3ac';

const Auth = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { isPhoneChecked, isSendingCode, idCode } = useSelector(state => state.auth);
  const [authCode, setAuthCode] = useState('');
  const loginSuccess = (userData) => {
  }
  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {
      "login": "",
      "password": ""
    }
  });

  const handleAuthCode = (e) => {
    setAuthCode(e.target.value)
  }

  const onSubmit = async (values) => {

    const dataSend = {
      ...values,
      code: authCode,
      idCode
    }

    const data = await dispatch(fetchAuth(dataSend));
    window.localStorage.setItem('formClose', false);
    window.localStorage.setItem('token', data.payload.accessToken);

    if (!data.payload) {
      return alert('Не удалось авторизоваться!');
    }

    if (data.payload) {

      window.localStorage.setItem('token', data.payload.accessToken);
      navigate("/")
    }
  };
  return (
    <div className="authorization">
      <div className="authorization__employees"
        style={{
          margin: '0 auto',
          justifyContent: 'center',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <YandexLogin clientID={clientID} onSuccess={loginSuccess}>
            <button className="nav__sign-modal-input nav__sign-modal-input-yandex">
              <img
                src='https://yastatic.net/s3/home/services/all/all_lite/Search_v3.svg'
                alt="yandex"
                height='30px' />
              Войти с Яндекс ID
            </button>
          </YandexLogin>
        </form>
      </div>
    </div>
  )
}

export default Auth;