import { useDispatch, useSelector } from "react-redux";
import RequestUser from "../../components/request-user/RequestUser";
import { selectIsAuth } from "../../redux/slices/auth";
import { Navigate } from "react-router-dom";
import { fetchRequest, fetchRequestDelete } from "../../redux/slices/request";
import React, { useEffect, useState } from "react";

const Request = () => {
  const [editingItemId, setEditingItemId] = useState(null);
  const [searchPhoneNumber, setSearchPhoneNumber] = useState('');

  const handleEditButtonClick = (itemId) => {
    setEditingItemId(itemId);
  }

  const handleCancelEdit = () => {
    setEditingItemId(null);
  }

  const handleSaveEdit = () => {
    // Обработка сохранения изменений
    setEditingItemId(null);
  }

  const isAuth = useSelector(selectIsAuth);

  const dispatch = useDispatch();
  const { request } = useSelector(state => state.request);

  useEffect(() => {
    dispatch(fetchRequest());
  }, [dispatch]);

  const handleRequestDelete = (id) => {
    dispatch(fetchRequestDelete(id));
  }

  const filter = (arr, search) => {
    // Возвращает массив с элементами, в которых есть совпадение по номеру телефона
    return arr.filter((item) => item.phone_number.includes(search))
  }
  // Фильтр по номеру телефона 
  const filteredRequests = filter(request.items, searchPhoneNumber);

  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to="/auth" />;
  }

  return (
    <>
      <div className="search">
        <h3>Заявки</h3>
        <div className="search__container">
          <input
            type="text"
            placeholder="Введите телефон"
            value={searchPhoneNumber}
            onChange={(e) => setSearchPhoneNumber(e.target.value)}
          />
        </div>
        <div className="clients">
          <h4>Заявок - {filteredRequests.length}</h4>
          {filteredRequests.reverse().map((item, index) => (
            <div key={index} className="client">
              {editingItemId === item._id ? (
                <>
                  <div className="client__container">
                    <input type="text" defaultValue={item.email} />
                    <input type="text" defaultValue={item.name} />
                    <input type="text" defaultValue={item.phone_number} />

                  </div>
                  <div className="client__container">
                    <button onClick={handleSaveEdit} className="button">Сохранить</button>
                    <button onClick={handleCancelEdit} className="button">Отменить</button>
                  </div>
                </>
              ) : (
                <>
                  <div className="client__container">
                    <div className="client__text">Почта: {item.email}</div>
                    <div className="client__text">Имя: {item.name}</div>
                    <div className="client__text">Номер телефона: {item.phone_number}</div>
                    <div className="client__text">Дата заявки: {new Date(item.date).toLocaleString()}</div>

                  </div>
                  <div className="client__container">
                    <button onClick={() => { handleRequestDelete(item._id) }} className="button">Удалить</button>
                    <button onClick={() => { handleEditButtonClick(item._id) }} className="button">Изменить</button>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default React.memo(Request);