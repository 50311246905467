import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchAuthYandex, selectIsAuth } from "../redux/slices/auth";
import { useNavigate } from "react-router-dom";

const YandexLoginRedirect = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  useEffect(() => {
    if (!isAuth) {
      const parts = window.location.href.split('#')
      const queryPartUrl = parts.length > 1 && parts[1] !== 'frame' ? parts[1] : null;
      if (!queryPartUrl) {
        return null;
      }
      const result = {};
      queryPartUrl.split("&").forEach(function (part) {
        const item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
      });
      if (result.access_token) {
        const fetchData = async () => {
          const data = await dispatch(fetchAuthYandex(result.access_token))
          if (!data.payload) {
            return alert('Не удалось зарегистрироваться!');
          }

          if ('accessToken' in data.payload) {
            localStorage.setItem('token', data.payload.accessToken);
            navigate("/")
          }

        }
        fetchData()

      }
    }
  }, [])





  return (
    <div>
      Проверка пользователя
      {/*  либо сделать какуюнибудь крутилку   */}
    </div>
  );
};

export default YandexLoginRedirect;