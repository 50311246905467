import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPromo, deletePromo, createPromo } from "../../redux/slices/promo";
import { useForm } from "react-hook-form";

const Promo = () => {
  const dispatch = useDispatch();

  const { data } = useSelector(state => state.promo)

  useEffect(() => {
    dispatch(fetchPromo());
  }, [dispatch]);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      "name": "",
      "discount": ""
    }
  });

  const onSubmit = async (values) => {
    values.discount = values.discount / 100
    dispatch(createPromo(values));
  };

  const onClickDeletePromo = async (id) => {
    await dispatch(deletePromo(id));
  };

  return (
    <div className="page__wrapper">
      <div className="search">
        <h3>Промокоды</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label>
              <input {...register('name', { required: 'Название промо-кода' })} placeholder="Название промо-кода" type="text" />
            </label>
            <label>
              <input {...register('discount', { required: 'Размер скидки' })} placeholder="Размер скидки" type="test" />
            </label>
          </div>
          <button className="button" type="submit">Создать</button>
        </form>
        <p>Промо-коды:</p>
        <div className="clients">
          {
            data.map(item =>
              <div className="client" key={item._id}>
                <div className="client__container">
                  <div className="client__text">Название промо-кода: {item.name}</div>
                  <div className="client__text">Размер скидки: {item.discount * 100}%</div>
                  <div onClick={() => onClickDeletePromo(item._id)} className="button">Удалить</div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Promo;