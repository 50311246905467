import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchOrder,
  fetchOrderChange,
  fetchOrderDelete,
  fetchOrderCancel,
} from '../../redux/slices/order'
import { selectIsAuth } from '../../redux/slices/auth'
import { Navigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

const Order = () => {
  const [isLoading, setIsLoading] = useState(true) // Изначально устанавливаем, что загрузка идет
  const [editingItemId, setEditingItemId] = useState(null)
  const [orderState, setOrderState] = useState(null)
  const [error, setError] = useState(null) // Добавлено состояние для ошибки
  const [search, setSearch] = useState('')

  const handleEditButtonClick = (itemId) => {
    setEditingItemId(itemId)
  }

  const handleCancelEdit = () => {
    setEditingItemId(null)
  }

  const dispatch = useDispatch()
  const { order } = useSelector((state) => state.order)

  // useEffect(() => {
  //   dispatch(fetchOrder())
  // }, [dispatch])

  useEffect(() => {
    setIsLoading(true) // Устанавливаем, что загрузка началась
    dispatch(fetchOrder())
      .then(() => {
        setIsLoading(false) // Загрузка завершена
      })
      .catch((error) => {
        setIsLoading(false) // Если произошла ошибка, также устанавливаем, что загрузка завершена
        setError('Не удалось загрузить заказы. Попробуйте еще раз.')
      })
  }, [dispatch])

  const isAuth = useSelector(selectIsAuth)

  const { register, handleSubmit } = useForm({
    defaultValues: {
      group: '',
      type: '',
      bathroom_count: '',
      additional_services: '',
      address: '',
      intercom: '',
      payment_type: '',
      comment: '',
      state: '',
    },
  })

  const onSubmit = async () => {
    let state = false
    if (orderState === 'Готов заказ') {
      state = true
    }
    try {
      const data = await dispatch(
        fetchOrderChange({ id: editingItemId, state })
      )
      return data
    } catch (error) {
      setError('Не удалось изменить заказ. Попробуйте еще раз.')
    }
  }

  const handleDelete = async (id) => {
    try {
      await dispatch(fetchOrderDelete(id))
      dispatch(fetchOrder()) // Обновление данных после удаления заказа
    } catch (error) {
      setError('Не удалось удалить заказ. Попробуйте еще раз.')
    }
  }

  const handleCancel = async (id) => {
    await dispatch(fetchOrderCancel(id))
    dispatch(fetchOrder())
  }
  const handleSave = () => {
    document.location.href = '/order'
  }

  const filter = (arr, search) => {
    const lowerCaseSearch = search.toLowerCase()

    if (!Array.isArray(arr)) {
      return []
    }

    return arr.filter((item) => {
      const addressMatches = item.address
        .toLowerCase()
        .includes(lowerCaseSearch)
      const phoneNumberMatches =
        item.phone_number && item.phone_number.includes(search)
      const orderNumberMatches =
        item.orderNumber && item.orderNumber.toString().includes(search)

      return addressMatches || phoneNumberMatches || orderNumberMatches
    })
  }

  const filteredOrders = filter(order.items, search)

  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to="/auth" />
  }

  if (isLoading) {
    return <h1>Загрузка...</h1>
  }

  return (
    <div className="search">
      <h3>Заказы</h3>
      <div className="search__container">
        <input
          type="text"
          placeholder="Введите телефон / Адрес / Номер заказа"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <h4>
        {/* Всего заказов -{' '} */}
        {/* {
          order.items.filter((item) => {
            if (item.isCanceled || item.state) {
              return false
            }
            if (!item.isPaid && item.payment_type === 'Безналичный расчет') {
              return false
            }
            if (item.payment_type === 'Наличные') {
              return true
            }
            return true
          }).length
        } */}
        {/* {order.items.length} */}
      </h4>

      <div className="clients">
        {Array.isArray(order.items) &&
          filteredOrders
            .reverse()
            .filter((item) => {
              if (item.isCanceled || item.state) {
                return false
              }
              if (!item.isPaid && item.payment_type === 'Безналичный расчет') {
                return false
              }
              if (item.payment_type === 'Наличные') {
                return true
              }
              return true
            })
            .map((item, index) => {
              const addresses = item.address.split(', ')

              return (
                <div key={index}>
                  {editingItemId === item._id ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="client" key={index}>
                        <div className="client__container">
                          <label htmlFor="">
                            <div className="client__container-input">
                              <div>Заказ готов</div>
                              <input
                                type="radio"
                                name={`state-${index}`}
                                value="Готов заказ"
                                checked={orderState === 'Готов заказ'}
                                onChange={(e) => setOrderState(e.target.value)}
                              />
                            </div>
                            <div className="client__container-input">
                              <div>Не Готов</div>
                              <input
                                type="radio"
                                name={`state-${index}`}
                                value="Не Готов"
                                checked={orderState === 'Не Готов'}
                                onChange={(e) => setOrderState(e.target.value)}
                              />
                            </div>
                          </label>
                        </div>
                        <div className="client__container">
                          <button
                            type="submit"
                            onClick={handleSave}
                            className="button"
                          >
                            Сохранить
                          </button>
                          <button onClick={handleCancelEdit} className="button">
                            Отменить
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <div className="client" key={index}>
                      <div className="client__container">
                        <div className="client__text">
                          {item.state ? (
                            <div className="client__state client__state--active">
                              <strong>Заказ готов</strong>
                            </div>
                          ) : (
                            <div className="client__state client__state--inactive">
                              <strong>Заказ не готов</strong>
                            </div>
                          )}

                          {item.isCanceled ? (
                            <div className="client__state client__state--active">
                              <strong>Заказ отменен</strong>
                            </div>
                          ) : (
                            <div className="client__state client__state--inactive">
                              <strong>Заказ в работе</strong>
                            </div>
                          )}
                        </div>
                        {item.user && (
                          <>
                            <div className="client__text">
                              Телефон: {item.user.phone_number}
                            </div>
                            <div className="client__text">
                              Имя заказчика: {item.user.name}
                            </div>
                            <div className="client__text">
                              Почта: {item.user.email}
                            </div>
                          </>
                        )}
                        <div className="client__text">
                          Номер заказа: №{item.orderNumber}
                        </div>
                        <div className="client__text">
                          Сумма уборки: {item.price}₽
                        </div>
                        <div className="client__text">
                          Бонусы: {item.bonusAmount}
                        </div>
                        <div className="client__text">
                          Итого сумма заказа: {item.price - item.bonusAmount}₽
                        </div>
                        <div className="client__text">Уборка: {item.group}</div>
                        <ul className="client__orders client__text">
                          {item.additional_services.map((service) => (
                            <li key={service.id}>
                              <div>Вид уборки: {service.name}</div>
                              <div>Цена: {service.price} ₽</div>
                              <div>Количество: {service.count}</div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="client__container">
                        <div className="client__text">
                          Тип помещения: {item.type}
                        </div>
                        <div className="client__text">
                          Количество ванных комнат: {item.bathroom_count}
                        </div>
                        <div className="client__text">
                          Дата: {new Date(item.date).toLocaleString()}
                        </div>
                        <div className="client__text">
                          Адрес:
                          {addresses.length > 0 ? (
                            <div>
                              Город: {addresses[0]}
                              <br />
                              Улица: {addresses[1]}
                              <br />
                              Домофон: {addresses[2]}
                            </div>
                          ) : null}
                        </div>
                        {/* <div className="client__text">
                          Домофон: {item.intercom}
                        </div> */}
                        <div className="client__text">
                          Оплата: {item.payment_type}
                        </div>
                        <div className="client__text">
                          Комментарий: {item.comment}
                        </div>
                      </div>
                      <div className="client__container">
                        <button
                          onClick={() => handleDelete(item._id)}
                          className="button"
                        >
                          Удалить
                        </button>
                        <button
                          onClick={() => handleCancel(item._id)}
                          className="button"
                        >
                          Отменить
                        </button>
                        <button
                          onClick={() => {
                            handleEditButtonClick(item._id)
                          }}
                          className="button"
                        >
                          Изменить
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
      </div>
      {error && <p>{error}</p>}
    </div>
  )
}

export default React.memo(Order)
