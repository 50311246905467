// import ServicesActive from "../../components/services-active/ServicesActive";
// import Services from "../../components/services/Services";
// import Story from "../../components/story/Story";
// import Calendar from "react-calendar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAuth } from "../../redux/slices/auth";
import { Navigate } from "react-router-dom";
import { fetchOrder, fetchOrderChange, fetchOrderDelete, fetchOrderCancel } from "../../redux/slices/order";
import "./Main.scss";

const Main = () => {
  // const [value, onChange] = useState(new Date());

  const [editingItemId, setEditingItemId] = useState(null);
  const [orderState, setOrderState] = useState(null);
  const [error, setError] = useState(null); // Добавлено состояние для ошибки
  const [search, setSearch] = useState('');


  const dispatch = useDispatch();
  const { order } = useSelector(state => state.order);

  useEffect(() => {
    dispatch(fetchOrder());
  }, [dispatch]);

  console.log(order);

  const isAuth = useSelector(selectIsAuth);

  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to="/auth" />;
  }

  return (
    <div className="page__wrapper">
      <section className="page__main">
        <p>Все заказы (до 10 штук)</p>
        {order.items
          .slice(0, 10)
          .map((item) => (
            <div className="client">
              <div className="client__container">
                {item.user && (
                  <>
                    <div className="client__text">Телефон: {item.user.phone_number}</div>
                    <div className="client__text">Имя заказчика: {item.user.name}</div>
                  </>
                )}
              </div>
              <div className="client__container">
                <div className="client__text">Оплата: {item.payment_type}</div>
                <div className="client__text">Сумма уборки: {item.price} ₽</div>
                <div className="client__text">Номер заказа: №{item.orderNumber}</div>
              </div>
            </div>
          ))}
        {/* <div className="page__main-container">
        </div> */}
      </section>
    </div>
  );
}

export default Main;